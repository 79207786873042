import { ISystemModalProps } from '../..';
import services from '../../services';
import { Popover, Tour, TourStepProps } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import useFetch from '@/hooks/useFetch';
import { showAIState } from '@/store/ai';
import useStyles from './style';

/** 智能客服地址 */
export const CUSTOMER_SERVICE_URL = '/feedback/intelligentCustomerService';
/** 不显示人工客服浮动按钮的URL */
export const NOT_SHOW_AI_BTN_URLS = [CUSTOMER_SERVICE_URL, '/feedback/prodSuggestion', '/feedback/customerComplaint/createComplaint'];

/**
 * @Author: Mars
 * @description: 智能客服入口
 * @return {*}
 */
const ICustomerService = (props: ISystemModalProps) => {
  const { onNext, ready } = props;
  const { styles } = useStyles();
  const { t } = useTranslation('clientFeedback');
  const { t: tb } = useTranslation('basicSettings');
  const ref = useRef(null);
  const [openTour, setOpenTour] = useState(false);
  const [showAI, setShowAI] = useRecoilState(showAIState);
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * @Author: Mars
   * @description: 按钮点击回调
   * @return {*}
   */
  const handleClick = () => {
    navigate(CUSTOMER_SERVICE_URL);
  };

  // 获取智能客服控制参数
  const { data, mutate } = useFetch(services.getAiServiceControl, {
    ready,
    onSuccess: (data) => {
      if (data.display) {
        setShowAI(true);
        if (data.firstLogin) {
          // 增加500毫秒延时渲染引导页，解决偶发的渲染位置错误的问题
          setTimeout(() => setOpenTour(true), 500);
        } else {
          onNext();
        }
      } else {
        onNext();
      }
    },
    onError: () => {
      onNext();
    },
  });

  /**
   * @Author: Mars
   * @description: 是否显示按钮
   * @param {*} useMemo
   * @return {*}
   */
  const showFloatBtn = useMemo(() => {
    // 初次登录时，无论什么页面都显示按钮
    return data?.firstLogin || (showAI && !NOT_SHOW_AI_BTN_URLS.includes(location.pathname));
  }, [showAI, location.pathname, data]);

  // 卸载时重置状态
  useEffect(() => {
    setShowAI(false);
  }, []);

  return (
    <>
      {/* 猫 */}
      <Popover overlayClassName={styles.popover} content={t('robotCusService.homeTips')} placement="leftTop" arrow={false}>
        <img
          ref={ref}
          onClick={handleClick}
          src={openTour ? '/img/iCustomerService/tour-kitty-icon.png' : '/img/iCustomerService/kitty-icon.png'}
          style={{ opacity: openTour ? 1 : undefined, display: showFloatBtn ? 'unset' : 'none' }}
          className={styles.floatBtn}
        />
      </Popover>
      {/* 遮罩 */}
      {openTour && <div className={styles.mask} />}
      {/* 引导 */}
      <Tour
        open={openTour}
        onClose={() => {
          setOpenTour(false);
          // 点击按钮后篡改firstLogin数据，隐藏按钮
          mutate({ display: true, firstLogin: false });
          onNext();
        }}
        disabledInteraction
        closeIcon={<></>}
        arrow={false}
        mask={false}
        rootClassName={styles.tour}
        steps={[
          {
            title: '',
            target: ref.current,
            placement: 'leftBottom',
            nextButtonProps: { children: tb('zxzh.knowBtn'), type: 'default', shape: 'round' } as TourStepProps['nextButtonProps'],
            description: <div className={styles.tourContent}>{t('robotCusService.newbieGuideTips')}</div>,
          },
        ]}
      />
    </>
  );
};

export default ICustomerService;
