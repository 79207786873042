// sort-imports-ignore
/** @jsxRuntime classic */
/** @jsx jsxCustomEvent */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import jsxCustomEvent from '@micro-zoe/micro-app/polyfill/jsx-custom-event';
// !此引用为micro-app在React下给子应用传递数据所需依赖，不可删除

import microApp from '@micro-zoe/micro-app';
import { Fragment, lazy, useEffect, useState } from 'react';
import { Navigate, RouteObject, createBrowserRouter, useLocation } from 'react-router-dom';
import { selector, useResetRecoilState } from 'recoil';
import LanguageProvider from '@/components/LanguageProvider';
import Panel from '@/components/Panel';
import config, { isDev } from '@/config';
import useMenu from '@/hooks/useMenu';
import { IMenuItem, IMicroItem, MenuType } from '@/services/user/types';
import menuState, { flattenMenuState } from '@/store/menu';
import { getMicroAppUrl, getNavigateUrl } from '@/utils/microApp';
import { getElement } from '@/utils/module';
import { breadState } from './store/bread';
import { Fallback } from '@alpha-ess/ui';

// * 以下值通过头部高度、padding等计算而出，提供给子应用用于渲染loading状态
/** 没有面包屑页面的视窗高度 */
const NO_PANEL_VIEW_HEIGHT = 'calc(100vh - 56px - 36px)';

/** 子应用有面包屑的视窗高度 */
const MICRO_PANEL_VIEW_HEIGHT = 'calc(100vh - 56px - 32px - 56px - 1px )';

/** 未登录时系统布局 */
const UserLayout = lazy(() => import('@/components/UserLayout'));
/** 布局页面 */
const LayoutPage = lazy(() => import('@/layout'));
/** 初始化页面 */
const InitPage = lazy(() => import('@/layout/init'));
/** 登录页 */
const LoginPage = lazy(() => import('@/pages/Login/indexNew.tsx'));
/** 第三方登录页 */
const ThirdLoginPage = lazy(() => import('@/pages/Login/ThirdLogin'));
/** 第三方登录重定向路由 */
const ThirdCallback = lazy(() => import('@/pages/Login/LoginCallback'));
/** 注册页 */
const RegisterPage = lazy(() => import('@/pages/Register'));
/** 分项注册页 */
const ShareRegisterPage = lazy(() => import('@/pages/ShareRegister'));
/** 忘记密码页 */
const ForgetPasswordPage = lazy(() => import('@/pages/ForgetPassword'));
/** 重置密码页 */
const ResetPasswordPage = lazy(() => import('@/pages/ResetPassword'));
/** 忘记用户名页 */
const ForgetUserNamePage = lazy(() => import('@/pages/ForgetUserName'));
/** 忘记用户名邮件发送成功页 */
const ForgetUserNameStepTwo = lazy(() => import('@/pages/ForgetUserNameStepTwo'));
/** 微前端测试页 */
const MicroAppTest = lazy(() => import('@/pages/MicroAppTest'));
/** 注册页隐私政策描述页 */
const RegisterAgreeDescription = lazy(() => import('@/pages/RegisterAgreeDescription'));
/** 客诉模块的隐私政策描述页 */
const ComplaintAgreeDescription = lazy(() => import('@/pages/ComplaintAgreeDescription'));
/** 注册成功后，邮件激活前页面 */
const RegisterStepTwo = lazy(() => import('@/pages/RegisterStepTwo'));
/** 注册成功后，邮件激活页面 */
const RegisterStepThree = lazy(() => import('@/pages/RegisterStepThree'));
/** 终端用户打开远程升级 */
const OpenRemoteUpgrade = lazy(() => import('@/pages/OpenRemoteUpgrade'));
/** 终端用户首页质保页面 */
const WarrantyCustomer = lazy(() => import('@/pages/Dashboard/Customer/components/Warranty'));
/** T10 终端用户页面 */
const OnlinePromotion = lazy(() => import('@/pages/OnlinePopUps/OnlinePromotion'));
/** T10 安装商页面 */
const OnlinePromotionInstall = lazy(() => import('@/pages/OnlinePopUps/OnlinePromotionInstall'));
/** 邮件推送 */
const EmailPush = lazy(() => import('@/pages/EmailPush'));
/** 账号激活 */
const AccountActivation = lazy(() => import('@/pages/AccountActivation'));
/** 延保 */
const ExtendWarranty = lazy(() => import('@/pages/ExtendWarranty'));
/** 延保服务 */
const ExtendedWarrantyService = lazy(() => import('@/pages/ExtendedWarrantyService'));
/** 延保服务-app说明 */
const AppDescription = lazy(() => import('@/pages/ExtendedWarrantyService/components/AppDescription'));
/** 延保服务-web说明 */
const WebDescription = lazy(() => import('@/pages/ExtendedWarrantyService/components/WebDescription'));
/** EVA注册成功后，邮件激活页面 */
const EVARegisterStepThree = lazy(() => import('@/pages/EVARegisterStepThree'));
/** 内置静态页面 */
const staticRoutes: RouteObject[] = [
  {
    /* 登录页 */
    path: '/login',
    element: <LanguageProvider>{config.thirdLogin ? <ThirdLoginPage /> : <LoginPage />}</LanguageProvider>,
  },
  {
    /* 登录页 */
    path: '/login/callback',
    element: (
      <LanguageProvider>
        <ThirdCallback />
      </LanguageProvider>
    ),
  },
  {
    /* 注册页 */
    path: '/register',
    element: (
      <UserLayout>
        <RegisterPage />
      </UserLayout>
    ),
  },
  {
    /* 分项注册页面 */
    path: '/shareRegister',
    element: <ShareRegisterPage />,
  },
  {
    /* 忘记密码页 */
    path: '/forgetPassword',
    element: (
      <UserLayout>
        <ForgetPasswordPage />
      </UserLayout>
    ),
  },
  {
    /* 重置密码页 */
    path: '/resetPassword',
    element: (
      <UserLayout>
        <ResetPasswordPage />
      </UserLayout>
    ),
  },
  {
    /* 忘记用户名页 */
    path: '/forgetUserName',
    element: (
      <UserLayout>
        <ForgetUserNamePage />
      </UserLayout>
    ),
  },
  {
    /* 忘记用户名邮件发送成功页 */
    path: '/forgetUserNameSecond',
    element: (
      <UserLayout>
        <ForgetUserNameStepTwo />
      </UserLayout>
    ),
  },
  {
    /* 注册隐私政策描述页面 */
    path: '/agreeDescription',
    element: (
      <LanguageProvider>
        <RegisterAgreeDescription />
      </LanguageProvider>
    ),
  },
  {
    /* 客诉模块的隐私政策描述页 */
    path: '/complaintAgreeDescription',
    element: (
      <LanguageProvider>
        <ComplaintAgreeDescription />
      </LanguageProvider>
    ),
  },
  {
    /* 注册成功后，邮件激活前的页面 */
    path: '/registerStepTwo',
    element: (
      <UserLayout>
        <RegisterStepTwo />
      </UserLayout>
    ),
  },
  {
    /* 注册成功后，邮件激活页 */
    path: '/registerActive',
    element: (
      <UserLayout>
        <LanguageProvider languageKey="Lang">
          <RegisterStepThree />
        </LanguageProvider>
      </UserLayout>
    ),
  },
  {
    /* 终端用户打开远程升级 */
    path: '/updateRemoteUpgrade',
    element: (
      <UserLayout>
        <LanguageProvider languageKey="Lang">
          <OpenRemoteUpgrade />
        </LanguageProvider>
      </UserLayout>
    ),
  },
  {
    /* T10 终端用户信息填写页面 */
    path: '/OnlinePromotion',
    element: <OnlinePromotion />,
  },
  {
    /* T10 安装商详情页面 */
    path: '/OnlinePromotionInstall',
    element: <OnlinePromotionInstall />,
  },
  {
    /* 邮件推送 */
    path: '/emailPush',
    element: (
      <LanguageProvider languageKey="langCode">
        <EmailPush />
      </LanguageProvider>
    ),
  },
  {
    /* 账号激活 */
    path: '/accountActivation',
    element: (
      <UserLayout>
        <AccountActivation />
      </UserLayout>
    ),
  },
  {
    /* 延保 */
    path: '/extendWarranty',
    element: (
      <LanguageProvider languageKey="langCode">
        <ExtendWarranty />
      </LanguageProvider>
    ),
  },
  {
    /* 延保 */
    path: '/extendedWarrantyService',
    element: (
      <LanguageProvider languageKey="langCode">
        <ExtendedWarrantyService />
      </LanguageProvider>
    ),
  },
  {
    /* 延保-app说明 */
    path: '/extendedWarrantyService/appDescription',
    element: (
      <LanguageProvider languageKey="langCode">
        <AppDescription />
      </LanguageProvider>
    ),
  },
  {
    /* 延保-we说b明 */
    path: '/extendedWarrantyService/webDescription',
    element: (
      <LanguageProvider languageKey="langCode">
        <WebDescription />
      </LanguageProvider>
    ),
  },
  {
    /* EVA注册成功后，邮件激活页 */
    path: '/registerActiveEva',
    element: (
      <UserLayout>
        <LanguageProvider languageKey="Lang">
          <EVARegisterStepThree />
        </LanguageProvider>
      </UserLayout>
    ),
  },
];

/** Layout内渲染静态页 */
const staticSubRoutes: RouteObject[] = [
  { path: '/no-auth', element: <div style={{ fontSize: '36px', fontWeight: 'bold', textAlign: 'center' }}>页面开小差了，请稍后再试</div> },
  { path: '/warranty', element: <WarrantyCustomer /> },
];

/** 路由状态 */
export const routeState = selector({
  key: 'routeState',
  get: ({ get }) => {
    const menuData = get(menuState);
    // 如果数据已经初始化，则渲染Layout,否则，进入Init组件进行基本信息获取
    if (menuData.menu) {
      const flattenMenu = get(flattenMenuState);
      const subRoutes: RouteObject[] = [];
      /** 需要预加载的子应用 */
      const prefetchApps: Parameters<(typeof microApp)['preFetch']>[0] = [];
      const { menu, microApps, defaultPage } = menuData;
      // 获取子路由
      subRoutes.push(...getRoutes(menu));
      // 批量注册微应用
      for (const app of microApps) {
        subRoutes.push({
          path: `/${app.systemWebCode}/*`,
          element: <MicroAppElement item={app} routes={flattenMenu.filter((it) => it.systemWebCode === app.systemWebCode)} />,
        });
        if (app.prefetch) {
          prefetchApps.push({
            name: app.systemWebCode,
            url: isDev ? app.devUrl : getMicroAppUrl(app.url),
            iframe: app.iframe,
          });
        }
      }
      // 3秒后预加载
      microApp.preFetch(prefetchApps, 3000);
      // 开发环境下，添加微应用测试页面
      if (isDev) {
        subRoutes.push({ path: '/micro-app-test', element: <MicroAppTest /> });
      }
      subRoutes.push(...staticSubRoutes);
      // 无效路由及空路由跳转地址
      subRoutes.push(...['*', '/'].map((url) => ({ path: url, element: <Navigate to={defaultPage || getDefaultPage(flattenMenu)} /> })));

      const routes: RouteObject[] = [
        ...staticRoutes,
        {
          path: '/',
          element: <LayoutPage />,
          children: subRoutes,
        },
      ];
      return createBrowserRouter(routes);
    } else {
      const routes: RouteObject[] = [
        ...staticRoutes,
        {
          path: '/*',
          element: <InitPage />,
        },
      ];
      return createBrowserRouter(routes);
    }
  },
});

/**
 * @Author: Mars
 * @description: 通过菜单数据生成路由
 * @param {IMenuItem} items
 * @return {*}
 */
export const getRoutes = (items: IMenuItem[]): RouteObject[] => {
  const routes: RouteObject[] = [];
  for (const item of items) {
    if (item.children && item.children.length > 0) routes.push(...getRoutes(item.children));
    else if (!item.systemWebCode) {
      routes.push({
        path: item.menuUrl,
        element: getElement(item),
      });
    }
  }
  return routes;
};

/**
 * @Author: Mars
 * @description: 获取微应用组件
 * @param {IMicroItem} item
 * @return {*}
 */
const MicroAppElement = ({ item, routes }: { item: IMicroItem; routes?: IMenuItem[] }) => {
  const location = useLocation();
  const { getMenuItemByPath } = useMenu();
  const [loading, setLoading] = useState(true);
  const resetBread = useResetRecoilState(breadState);
  const currentItem = getMenuItemByPath(location.pathname);
  /** 不渲染面板 */
  const noPanel = currentItem?.extra?.split('|').includes('noPanel');
  /** 不渲染padding */
  const WarpElement = noPanel ? Fragment : Panel;
  /** 渲染区域视窗高度 */
  const viewPortHeight = noPanel ? NO_PANEL_VIEW_HEIGHT : MICRO_PANEL_VIEW_HEIGHT;
  const url = isDev ? item.devUrl : getMicroAppUrl(item.url);

  // 切换子应用时，重新设置loading状态
  useEffect(() => {
    setLoading(true);
  }, [item.systemWebCode]);

  return (
    <WarpElement key={item.systemWebCode}>
      {loading && <Fallback showFullParent />}
      <micro-app
        name={item.systemWebCode}
        url={url}
        iframe={item.iframe}
        keep-alive={item.keepAlive}
        data={{ routes, viewPortHeight }}
        router-mode="native"
        baseroute={`/${item.systemWebCode}`}
        onMounted={() => setLoading(false)}
        onUnmount={() => resetBread()}
      />
    </WarpElement>
  );
};

/**
 * @Author: Mars
 * @description: 获取默认页面
 * @param {IMenuItem} items
 * @return {*}
 */
export const getDefaultPage = (items: IMenuItem[]) => {
  for (const item of items) {
    if (item.menuType === MenuType.page) {
      if (item.systemWebCode) {
        return getNavigateUrl(item);
      } else return item.menuUrl;
    }
  }
  return '/no-auth';
};
