/*
 * @Description: 用户hook
 * @Author: Mars
 * @Date: 2023-12-05 11:29:54
 * @LastEditTime: 2024/12/13 13:44:37
 */
import { useLanguage, useStationMode, useUser } from '@alpha-ess/store';
import { getBrowserLanguage, removeToken, setDataCenter, setDataCenterType } from '@alpha-ess/utils';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import config from '@/config';
import { defaultMenuState } from '@/store/menu';
import { unreadMsgCountState } from '@/store/message';
import {
  clearSessionId,
  removeRefreshToken,
  setCurrentSystemType,
  setLocalSn,
  setStationMode as setLocalStationMode,
  setLocalUserId,
  setShowExchangeSystemBtn,
} from '@/utils/localStorage';
import useMenu from './useMenu';

const useAuth = () => {
  const { user, setUser } = useUser();
  const { setMenuData } = useMenu();
  const { setLanguage } = useLanguage();
  const navigate = useNavigate();
  const { setStationMode } = useStationMode();
  const [unreadMsgTimestamp, setUnreadMsgTimestamp] = useRecoilState(unreadMsgCountState);
  const isLogin = Boolean(user.userId);
  /**
   * @Author: Mars
   * @description: 退出登录
   * @return {*}
   */
  const logout = () => {
    removeToken();
    removeRefreshToken();
    setLocalUserId('');
    setUser(null);
    setMenuData(defaultMenuState);
    // 退出登录时，切换到浏览器语言
    const browserLanguage = getBrowserLanguage();
    if (config.languageList.some((it) => it.value === browserLanguage)) {
      setLanguage(browserLanguage);
    }
    // 退出登录时，重置站点模式状态
    setStationMode(false);
    setLocalStationMode(false);
    setLocalSn('');
    clearSessionId();
    setShowExchangeSystemBtn('no');
    setDataCenter('');
    setDataCenterType('');
    setCurrentSystemType('');
    navigate('/login');
  };

  return {
    user,
    logout,
    isLogin,
    setUser,
    /** 设置未读消息刷新时间戳 */
    setUnreadMsgTimestamp,
    /** 未读消息刷新时间戳 */
    unreadMsgTimestamp,
  };
};

export default useAuth;
